<template>
  <div>
    <b-card title="名片基本信息">

    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, onMounted, computed, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, integer } from '@validations'
export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    formValidation,
    ValidationProvider,
    ValidationObserver,
  },

  setup(props, { root, emit }) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    const { $can } = root
    // 消息提醒组件
    const toast = useToast()

    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */

    // 表单 dom 对象
    const refFormObserver = ref(null)

    /* 接口调用区域 */


    /* 组件内部逻辑处理，生命周期问题处理区域 */

    // 处理基本下拉信息加载，权限判断等
    onMounted(() => {})

    /* 分割线 */

    return {
      required,
      integer,
      positiveInteger,

      refFormObserver,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>